module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-PX0LHPPJ8G","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Artifiapps | Website and Mobile application development services","short_name":"Artifiapps","description":"We are expertise in software development services like Website, eCommerce, WordPress, Android apps, iOS apps, CRM, SEO Optimization, Chatbot and more. We can build fully optimized and performative Website and Mobile applications for you. We are hooked with your business success and growth.","lang":"en","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#fff","theme_color":"#750289","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4eee7fd14f1bb0ae349b51b67ae0a5f3"},
    }]
